const defState = {
  theme: "default",
  src: "img0.jpg"
}

const wallReducer = (state=defState, action)=>{
  switch(action.type){
    default:
      return state
  }
}

export default wallReducer;
